import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import useDimensions from "react-cool-dimensions";
import Spinner from "./Spinner";
import LightboxImageSlider from "./LightboxImageSlider";
import { useAtom } from "jotai";
import { showLightboxAtom } from "../../jotai";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";

const ACTIVATE_40x50_IMAGE_SLIDER = true;

const ImageSlider = ({
  selectedSizeVariant,
  selectedPassepartoutVariant,
  selectedFrameVariant,
  dimension,
  isSimpleProduct,
}) => {
  console.log("dimension", dimension);
  console.log("isSimpleProduct", isSimpleProduct);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [showLightbox, setShowLightbox] = useAtom(showLightboxAtom);

  const [change, setChange] = useState(false);

  const isAllowedToScrollThumbnail = useRef(false);

  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
    skipSnaps: false,
  });

  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel(
    {
      skipSnaps: false,
      axis: "y",
      containScroll: "keepSnaps",
      dragFree: true,
    },
    [WheelGesturesPlugin()]
  );

  const onThumbnailClick = useCallback(
    (index) => {
      if (!emblaMainApi || !emblaMainApi) return;
      emblaMainApi.scrollTo(index);
      emblaThumbsApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const syncThumbnails = useCallback(
    (index) => {
      if (isAllowedToScrollThumbnail.current) {
        return;
      }

      emblaThumbsApi.scrollTo(index);
    },
    [emblaThumbsApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;

    const index = emblaMainApi.selectedScrollSnap();
    setSelectedIndex(index);
    syncThumbnails(index);
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  const handleMouseEnter = () => {
    isAllowedToScrollThumbnail.current = true;
  };

  const handleMouseLeave = () => {
    isAllowedToScrollThumbnail.current = false;
  };

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaThumbsApi.on("select", onSelect);
  }, [emblaMainApi, onSelect]);

  const [mainImageHeight, setMainImageHeight] = useState(0);

  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      setMainImageHeight(height);
    },
  });

  const getImageScale = (hasPassepartout, hasFrame, isThumbnail = false) => {
    if (
      selectedSizeVariant?.slug === "400x500mm" &&
      ACTIVATE_40x50_IMAGE_SLIDER &&
      isSimpleProduct &&
      dimension !== "landscape"
    ) {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout && !hasFrame) {
          return 0.55;
        } else if (!hasPassepartout && hasFrame) {
          return 0.74;
        } else if (hasPassepartout && hasFrame) {
          return 0.55;
        } else {
          return 0.74;
        }
      }
    } else {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout && !hasFrame) {
          return 0.57;
        } else if (!hasPassepartout && hasFrame) {
          return 0.75;
        } else if (hasPassepartout && hasFrame) {
          return 0.57;
        } else {
          return 0.75;
        }
      }
    }
  };

  const passepartoutScaleX = (sizeSlug) => {
    if (
      sizeSlug === "400x500mm" &&
      ACTIVATE_40x50_IMAGE_SLIDER &&
      isSimpleProduct &&
      dimension !== "landscape"
    ) {
      return 0.98;
    } else {
      return 1;
    }
  };

  const passepartoutScaleY = (sizeSlug) => {
    if (
      sizeSlug === "400x500mm" &&
      ACTIVATE_40x50_IMAGE_SLIDER &&
      isSimpleProduct &&
      dimension !== "landscape"
    ) {
      return 0.89;
    } else {
      return 1;
    }
  };

  const getBoxShadowScaleY = (
    hasPassepartout,
    hasFrame,
    isThumbnail = false
  ) => {
    if (
      selectedSizeVariant?.slug === "400x500mm" &&
      ACTIVATE_40x50_IMAGE_SLIDER &&
      isSimpleProduct &&
      dimension !== "landscape"
    ) {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout) {
          return 0.528;
        } else {
          return 0.675;
        }
      }
    } else {
      if (isThumbnail) {
        if (dimension === "landscape") {
          if (ACTIVATE_40x50_IMAGE_SLIDER && isSimpleProduct) {
            return 0.436;
          } else {
            return 0.392;
          }
        } else if (dimension === "square") {
          return 0.54;
        } else {
          return 0.75;
        }
      } else {
        if (hasPassepartout) {
          if (dimension === "square") {
            return 0.532;
          } else if (dimension === "landscape") {
            return 0.392;
          } else {
            return 0.745;
          }
        } else {
          if (dimension === "landscape") {
            // return 0.763;
            if (ACTIVATE_40x50_IMAGE_SLIDER && isSimpleProduct) {
              return 0.436;
            } else {
              return 0.392;
            }
          } else if (dimension === "square") {
            return 0.54;
          } else {
            return 0.75;
          }
        }
      }
    }
  };
  const getBoxShadowScaleX = (
    hasPassepartout,
    hasFrame,
    dimension,
    isThumbnail = false
  ) => {
    if (
      selectedSizeVariant?.slug === "400x500mm" &&
      ACTIVATE_40x50_IMAGE_SLIDER &&
      isSimpleProduct &&
      dimension !== "landscape"
    ) {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout) {
          return 0.577;
        } else {
          return 0.74;
        }
      }
    } else {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout) {
          if (dimension === "landscape") {
            return 0.743;
          } else if (dimension === "square") {
            return 0.732;
          } else {
            return 0.731;
          }
        } else {
          if (dimension === "landscape") {
            return 0.75;
          } else {
            return 0.75;
          }
        }
      }
    }
  };

  useEffect(() => {
    onThumbnailClick(0);
  }, [
    selectedSizeVariant,
    selectedPassepartoutVariant?.desktopImageUrl,
    selectedFrameVariant?.frameImage,
  ]);

  useEffect(() => {
    setChange(true);

    setTimeout(() => {
      setChange(false);
    }, 1000);
  }, [
    JSON.stringify(selectedSizeVariant),
    JSON.stringify(selectedPassepartoutVariant),
    JSON.stringify(selectedFrameVariant),
  ]);

  console.log("singleProductImages", singleProductImages);

  let productImages;

  if (!isPassepartoutProduct && !isFrameProduct) {
    if (singleProductImages?.length > 1) {
      const [first, ...rest] = singleProductImages;
      console.log("productImages first and rest", first, rest);

      rest.unshift(rest.pop());

      productImages = rest;
    } else {
      productImages = singleProductImages;
    }
  } else {
    productImages = singleProductImages;
  }

  console.log("productImages", productImages);

  return (
    <>
      <motion.div
        className="embla-thumbs overflow-y-auto mr-3 shrink-0 relative hidden sm:block hide-scrollbar"
        animate={{ maxHeight: mainImageHeight }}
      >
        <div
          className="embla-thumbs__viewport relative w-[168px]"
          style={{ height: `${mainImageHeight}px` }}
          ref={emblaThumbsRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="embla-thumbs__container gap-3 flex flex-col hide-scrollbar">
            {productImages.map((productImage, index) => (
              <div
                key={index}
                className={clsx(
                  "embla-thumbs__slide flex-[0_0_100%] min-w-0 relative transition-opacity duration-300 ease-in-out cursor-pointer hover:opacity-100",
                  isLimitedEditionsProduct
                    ? "bg-[#ececec]"
                    : "bg-gradient-to-r from-[#f6f6f6] to-[#d9d9d9]",
                  selectedSizeVariant?.slug === "400x500mm" &&
                    ACTIVATE_40x50_IMAGE_SLIDER &&
                    isSimpleProduct &&
                    dimension !== "landscape"
                    ? "aspect-[800/1000]"
                    : "aspect-[800/1100]",
                  selectedIndex === index ? "opacity-100" : "opacity-50",
                  0 === index &&
                    (dimension === "landscape" || dimension === "square") &&
                    "flex",
                  selectedSizeVariant?.slug === "400x500mm" &&
                    ACTIVATE_40x50_IMAGE_SLIDER &&
                    isSimpleProduct &&
                    dimension !== "landscape" &&
                    "flex"
                )}
                onClick={() => onThumbnailClick(index)}
              >
                <AnimatePresence>
                  {(selectedSizeVariant ||
                    selectedPassepartoutVariant?.passepartoutImage) &&
                  !isPassepartoutProduct &&
                  !isFrameProduct &&
                  !isTransparentArtPrintProduct &&
                  !isLimitedEditionsProduct &&
                  !isWallObjectsProduct &&
                  index === 0 ? (
                    <motion.div
                      initial={{
                        opacity: 0,
                        scaleY: getBoxShadowScaleY(
                          selectedPassepartoutVariant?.desktopImageUrl,
                          selectedFrameVariant?.frameImage,
                          true
                        ),
                        scaleX: getBoxShadowScaleX(
                          selectedPassepartoutVariant?.desktopImageUrl,
                          selectedFrameVariant?.frameImage,
                          dimension,
                          true
                        ),
                        top:
                          dimension === "landscape" || dimension === "square"
                            ? "50%"
                            : "auto",
                        y:
                          dimension === "landscape" || dimension === "square"
                            ? "-50%"
                            : "0",
                      }}
                      animate={{
                        opacity: 1,
                        scaleY: getBoxShadowScaleY(
                          selectedPassepartoutVariant?.desktopImageUrl,
                          selectedFrameVariant?.frameImage,
                          true
                        ),
                        scaleX: getBoxShadowScaleX(
                          selectedPassepartoutVariant?.desktopImageUrl,
                          selectedFrameVariant?.frameImage,
                          dimension,
                          true
                        ),
                        top:
                          dimension === "landscape" || dimension === "square"
                            ? "50%"
                            : "auto",
                        y:
                          dimension === "landscape" || dimension === "square"
                            ? "-50%"
                            : "0",
                      }}
                      exit={{ opacity: 0 }}
                      style={{
                        boxShadow:
                          0 === index
                            ? "-2.4px 2.4px 5.2px 0px rgba(0,0,0,0.15)"
                            : "none",
                      }}
                      className={clsx(
                        "embla__slide__img block absolute left-0 w-full origin-center pointer-events-none",
                        dimension === "landscape" ? "top-1/2" : "top-0",
                        dimension === "portrait" &&
                          selectedSizeVariant?.slug === "400x500mm" &&
                          ACTIVATE_40x50_IMAGE_SLIDER &&
                          isSimpleProduct &&
                          "aspect-[800/1000]",
                        dimension === "portrait" &&
                          selectedSizeVariant?.slug !== "400x500mm" &&
                          "aspect-[800/1100]",
                        dimension === "landscape" && "aspect-[1389/992]",
                        dimension === "square" && "aspect-square",
                        index !== 0 ? "hidden z-0" : "z-40"
                      )}
                    ></motion.div>
                  ) : null}
                </AnimatePresence>
                <img
                  className={clsx(
                    "embla-thumbs__slide__img block w-[168px] h-auto",
                    {
                      "translate-y-0":
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isTransparentArtPrintProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct &&
                        index === 0,
                      "scale-75":
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isTransparentArtPrintProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct &&
                        index === 0,
                    }
                  )}
                  src={productImage?.gallery[0]}
                  width="271"
                  height="345"
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
      </motion.div>
      <div className="flex flex-col">
        <div
          className={clsx(
            "relative",
            0 === selectedIndex &&
              (dimension === "landscape" || dimension === "square") &&
              "flex"
          )}
        >
          <div className="embla overflow-hidden cursor-zoom-in" ref={observe}>
            <div className="embla__viewport relative" ref={emblaMainRef}>
              <div className="embla__container flex">
                {productImages.map((productImage, index) => (
                  <div
                    key={index}
                    className={clsx(
                      "embla__slide flex-[0_0_100%] min-w-0 relative z-40",
                      isLimitedEditionsProduct
                        ? "bg-[#ececec]"
                        : "bg-gradient-to-r from-[#f6f6f6] to-[#d9d9d9]",
                      0 === index &&
                        (dimension === "landscape" || dimension === "square") &&
                        "flex",
                      selectedSizeVariant?.slug === "400x500mm" &&
                        ACTIVATE_40x50_IMAGE_SLIDER &&
                        isSimpleProduct &&
                        "flex"
                    )}
                    onClick={() => setShowLightbox(true)}
                  >
                    <AnimatePresence>
                      {(selectedSizeVariant ||
                        selectedPassepartoutVariant?.passepartoutImage) &&
                        !selectedFrameVariant?.frameImage &&
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isTransparentArtPrintProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct && (
                          <motion.div
                            initial={{
                              opacity: 0,
                              scaleY: getBoxShadowScaleY(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage
                              ),
                              scaleX: getBoxShadowScaleX(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage,
                                dimension
                              ),
                              top:
                                dimension === "landscape" ||
                                dimension === "square"
                                  ? "50%"
                                  : "auto",
                              y:
                                dimension === "landscape" ||
                                dimension === "square"
                                  ? "-50%"
                                  : "0",
                            }}
                            animate={{
                              opacity: 1,
                              scaleY: getBoxShadowScaleY(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage
                              ),
                              scaleX: getBoxShadowScaleX(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage,
                                dimension
                              ),
                              top:
                                dimension === "landscape" ||
                                dimension === "square"
                                  ? "50%"
                                  : "auto",
                              y:
                                dimension === "landscape" ||
                                dimension === "square"
                                  ? "-50%"
                                  : "0",
                            }}
                            exit={{ opacity: 0 }}
                            style={{
                              boxShadow:
                                0 === index
                                  ? "-2.4px 2.4px 5.2px 0px rgba(0,0,0,0.15)"
                                  : "none",
                            }}
                            className={clsx(
                              "embla__slide__img block absolute left-0 w-full origin-center pointer-events-none",
                              dimension === "landscape" ? "top-1/2" : "top-0",
                              dimension === "portrait" && "aspect-[800/1100]",
                              dimension === "landscape" && "aspect-[1389/992]",
                              dimension === "square" && "aspect-square",
                              index !== 0 ? "hidden z-0" : "z-40"
                            )}
                          />
                        )}
                    </AnimatePresence>
                    <motion.img
                      key="main-image"
                      // layout
                      initial={{
                        scale:
                          index === 0 &&
                          !isPassepartoutProduct &&
                          !isFrameProduct &&
                          !isTransparentArtPrintProduct &&
                          !isLimitedEditionsProduct &&
                          !isWallObjectsProduct
                            ? getImageScale(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage
                              )
                            : 1,
                      }}
                      animate={{
                        scale:
                          index === 0 &&
                          !isPassepartoutProduct &&
                          !isFrameProduct &&
                          !isTransparentArtPrintProduct &&
                          !isLimitedEditionsProduct &&
                          !isWallObjectsProduct
                            ? getImageScale(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage
                              )
                            : 1,
                      }}
                      className={clsx(
                        "embla__slide__img block origin-center",
                        index === 0 &&
                          (dimension === "landscape" ||
                            dimension === "square") &&
                          "self-center",
                        selectedSizeVariant?.slug === "400x500mm" &&
                          ACTIVATE_40x50_IMAGE_SLIDER &&
                          isSimpleProduct &&
                          "self-center"
                      )}
                      src={productImage?.main[0]}
                      width={800}
                      height={
                        selectedSizeVariant?.slug === "400x500mm" &&
                        ACTIVATE_40x50_IMAGE_SLIDER &&
                        isSimpleProduct &&
                        dimension !== "landscape"
                          ? 1000
                          : 1100
                      }
                      alt=""
                    />
                    <AnimatePresence>
                      {selectedPassepartoutVariant?.passepartoutImage &&
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isTransparentArtPrintProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct && (
                          <motion.img
                            initial={{
                              scaleX: passepartoutScaleX(
                                selectedSizeVariant?.slug
                              ),
                              scaleY: passepartoutScaleY(
                                selectedSizeVariant?.slug
                              ),
                              y: dimension === "landscape" ? "-50%" : 0,
                              opacity: 0,
                            }}
                            animate={{
                              scaleX: passepartoutScaleX(
                                selectedSizeVariant?.slug
                              ),
                              scaleY: passepartoutScaleY(
                                selectedSizeVariant?.slug
                              ),
                              y: dimension === "landscape" ? "-50%" : 0,
                              opacity: 1,
                            }}
                            exit={{ opacity: 0 }}
                            transition={{
                              // duration: 0.1,
                              type: "tween",
                            }}
                            className={clsx(
                              "embla__slide__img block absolute left-0 z-40",
                              dimension === "landscape" ? "top-1/2" : "top-0",
                              index !== 0 ? "hidden z-0" : "z-40"
                            )}
                            src={selectedPassepartoutVariant.passepartoutImage}
                            width={800}
                            height={
                              selectedSizeVariant?.slug === "400x500mm" &&
                              ACTIVATE_40x50_IMAGE_SLIDER &&
                              isSimpleProduct &&
                              dimension !== "landscape"
                                ? 1000
                                : 1100
                            }
                            alt=""
                          />
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                      {selectedFrameVariant?.frameImage &&
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isTransparentArtPrintProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct && (
                          <motion.img
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                              // duration: 0.1,
                              type: "tween",
                            }}
                            className={clsx(
                              "embla__slide__img block absolute left-0 z-40",
                              dimension === "landscape"
                                ? "top-1/2 -translate-y-1/2"
                                : "top-0",
                              index !== 0 ? "hidden z-0" : "z-40"
                            )}
                            src={selectedFrameVariant.frameImage}
                            width={800}
                            height={
                              selectedSizeVariant?.slug === "400x500mm" &&
                              ACTIVATE_40x50_IMAGE_SLIDER &&
                              isSimpleProduct &&
                              dimension !== "landscape"
                                ? 1000
                                : 1100
                            }
                            alt=""
                          />
                        )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="embla__dots flex gap-4 justify-center mt-[18px] sm:hidden">
          {productImages.map((_, index) => (
            <div
              key={index}
              onClick={() => onThumbnailClick(index)}
              className={clsx(
                "w-2 h-2 rounded-full",
                index === selectedIndex ? "bg-black" : "bg-tpc-darker"
              )}
            />
          ))}
        </div>
      </div>
      <AnimatePresence>
        {showLightbox && (
          <motion.div
            key="lightbox"
            className="fixed top-0 left-0 w-screen h-[100dvh] bg-white bg-opacity-90 z-[9999]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LightboxImageSlider
              selectedSizeVariant={selectedSizeVariant}
              selectedPassepartoutVariant={selectedPassepartoutVariant}
              selectedFrameVariant={selectedFrameVariant}
              dimension={dimension}
              selectedIndexFromParent={selectedIndex}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ImageSlider;
